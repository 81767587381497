<!-- <div class="row d-flex justify-content-center align-items-center">
  <div class="fixedbutton">
    <ngb-toast-container class="d-flex justify-content-center"></ngb-toast-container>
  </div>
</div> -->


<router-outlet>
  <app-spinner></app-spinner>
</router-outlet>
