<div class="home-btn d-none d-sm-block">
    <a routerLink=""><i class="mdi mdi-home-variant h2 text-white"></i></a>
</div>
<div>
    <div class="container-fluid p-0">
        <div class="row no-gutters">
            <div class="col-lg-4">
                <div class="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                    <div class="w-100">
                        <div class="row justify-content-center">
                            <div class="col-lg-9">
                                <div>
                                    <div class="text-center">
                                        <div>
                                            <a routerLink="" class="logo"><img src="assets/images/logo-dark.svg" height="50" alt="logo"></a>
                                        </div>

                                        <h4 class="font-size-18 mt-4">Bienvenido</h4>
                                        <p class="text-muted">Inicia sesión para continuar</p>

                                    </div>

                                    <div class="p-2 mt-5">
                                        <form class="form-horizontal" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                                            <ngb-alert type="danger" *ngIf="error" [dismissible]="false">{{ error }}
                                            </ngb-alert>
                                            <div class="form-group auth-form-group-custom mb-4">
                                                <i class="ri-user-2-line auti-custom-input-icon"></i>
                                                <label for="user">Usuario</label>
                                                <input type="number" formControlName="user" class="form-control" id="user" placeholder="Ingrese Identificacion" >
                                                <div *ngIf="submitted && f.user.errors" class="invalid-feedback">
                                                    <div *ngIf="f.email.errors.required">El campo usuario es obligatorio
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="form-group auth-form-group-custom mb-4">
                                                <i class="ri-lock-2-line auti-custom-input-icon"></i>
                                                <label for="password">Password</label>
                                                <input type="password" formControlName="password" class="form-control" id="password" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" placeholder="Ingrese Contraseña" />
                                                <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                                                    <div *ngIf="f.password.errors.required">PEl campo password es obligatorio
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="custom-control custom-checkbox">
                                                <input type="checkbox" class="custom-control-input" formControlName="remember" id="remember">
                                                <label class="custom-control-label" for="remember">Recordarme</label>
                                            </div>

                                            <div class="mt-4 text-center">
                                                <button class="btn btn-primary w-md waves-effect waves-light" type="submit">Iniciar Sesión</button>
                                            </div>

                                            <div class="mt-4 text-center">
                                                <a routerLink="/account/reset-password" class="text-muted"><i
                                                        class="mdi mdi-lock mr-1"></i>
                                                    Recuperar Contraseña</a>
                                            </div>
                                            <div class="mt-5 text-center">
                                                <p>
                                                    © {{year}} ATENEO ERP
                                                </p>
                                            </div>
                                        </form>
                                    </div>


                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-8">
                <div class="authentication-bg">
                    <div class="bg-overlay"></div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- end page -->