import { ReplaySubject, Observable } from 'rxjs';
import Swal from 'sweetalert2';

const SwalMsje = Swal.mixin({
  customClass: {
    confirmButton: 'btn btn-success mx-2',
    cancelButton: 'btn btn-danger',
  },
  buttonsStyling: false,
});

export const functionsUtils = {

  deleteByValue: function (obj, val) {
    for (var f in obj) {
      if (obj[f].id == val) {
        delete obj[f];
      }
    }
  },

  normalize: (function () {
    var from = 'ÃÀÁÄÂÈÉËÊÌÍÏÎÒÓÖÔÙÚÜÛãàáäâèéëêìíïîòóöôùúüûÑñÇçÂ®Ã\n',
      to = 'AAAAAEEEEIIIIOOOOUUUUaaaaaeeeeiiiioooouuuuNnccARA ',
      mapping = {};

    for (var i = 0, j = from.length; i < j; i++)
      mapping[from.charAt(i)] = to.charAt(i);

    return function (str) {
      var ret = [];
      for (var i = 0, j = str.length; i < j; i++) {
        var c = str.charAt(i);
        if (mapping.hasOwnProperty(str.charAt(i))) ret.push(mapping[c]);
        else ret.push(c);
      }
      return ret.join('');
    };
  })(),
  fileToBase64: function (file: File): Observable<string> {
    const result = new ReplaySubject<string>(1);
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = (event) =>
      result.next(btoa(event.target.result.toString()));
    return result;
  },

  validateCodeCreate: function (resp) {
    if (resp.code != 201) {
      throw new Error(resp.err);
    }
  },

  SwalMsje: function (title, des, type) {
    SwalMsje.fire(title, des, type);
  },

  validateField: function (object, properties) {
    for (const property in properties) {
      console.log([
        typeof object == 'undefined',
        !object.hasOwnProperty(property),
        object.property == '',
      ]);

      if (
        typeof object == 'undefined' ||
        !object.hasOwnProperty(property) ||
        object.property == ''
      ) {
        return false;
      }
    }
    return true;
  },
  utf8_encode: function (string) {
    // public method for url encoding
    string = string.replace(/\r\n/g, '\n');
    var utftext = '';
    for (var n = 0; n < string.length; n++) {
      var c = string.charCodeAt(n);
      if (c < 128) {
        utftext += String.fromCharCode(c);
      } else if (c > 127 && c < 2048) {
        utftext += String.fromCharCode((c >> 6) | 192);
        utftext += String.fromCharCode((c & 63) | 128);
      } else {
        utftext += String.fromCharCode((c >> 12) | 224);
        utftext += String.fromCharCode(((c >> 6) & 63) | 128);
        utftext += String.fromCharCode((c & 63) | 128);
      }
    }
    return utftext;
  },
  utf8_decode: function (utftext) {
    var string = '';
    var i = 0;
    var c = 0;
    var c2 = 0;
    var c3 = 0;

    while (i < utftext.length) {
      c = utftext.charCodeAt(i);
      if (c < 128) {
        string += String.fromCharCode(c);
        i++;
      } else if (c > 191 && c < 224) {
        c2 = utftext.charCodeAt(i + 1);
        string += String.fromCharCode(((c & 31) << 6) | (c2 & 63));
        i += 2;
      } else {
        c2 = utftext.charCodeAt(i + 1);
        c3 = utftext.charCodeAt(i + 2);
        string += String.fromCharCode(
          ((c & 15) << 12) | ((c2 & 63) << 6) | (c3 & 63)
        );
        i += 3;
      }
    }
    return string;
  },

  IsObjEmpty(obj) {
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  },

  HexadecimalAleatorio() {
    var text = "";
    var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (var i = 0; i < 5; i++)
      text += possible.charAt(Math.floor(Math.random() * possible.length));

    return text;
  },



  showErros(resp: any) {
    let html = `<ul>`;
    for (var clave in resp.err) {
      for (var error in resp.err[clave]) {
        console.log(error);
        html += `<li> ${resp.err[clave][error]}</li>`
      }
    }
    html += `</ul>`
    Swal.fire('Error', html, 'error');
  },



  downloadFile(data: Blob) {
    var link = document.createElement('a');
    link.href = window.URL.createObjectURL(data);
    link.download = 'qrReference';
    link.click();
  },



  showErros2(resp: any) {
    let html = `<ul>`;
    for (var clave in resp.error) {
      for (var error in resp.error[clave]) {
        console.log(error);
        html += `<li> ${resp.error[clave][error]}</li>`
      }
    }
    html += `</ul>`
    Swal.fire('Error', html, 'error');
  }

};
