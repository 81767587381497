<div class="container"
  *ngIf="this.nextConfig.layout === 'horizontal' && this.nextConfig.subLayout === 'horizontal-2'; else mainHeader">
  <ng-container *ngTemplateOutlet="mainHeader"></ng-container>
</div>
<ng-template #mainHeader>
  <div class="m-header">
    <a href="javascript:" class="mobile-menu" id="mobile-collapse" (click)="navCollapse()"><span></span></a>
    <a [routerLink]="['/dashboard/default']" class="b-brand">
      <!-- Home -->

      <img class="mb-2" id="main-logo" width="100em" src="https://images.squarespace-cdn.com/content/v1/5c8eb55890f90424c78f3f45/1557757161050-3COGAVPHE7CJW7WEBP0O/logo2.png?format=1500w" alt="" class="logo">

      <!-- <img id="main-logo" height="50px" src="assets/images/logo.png" alt="" class="logo"> -->
      <!-- <img src="assets/images/logo-icon.png" alt="" class="logo-thumb"> -->
    </a>
    <a class="mob-toggler" [ngClass]="{'on' : this.menuClass}" href="javascript:" (click)="toggleMobOption()"><i
        class="feather icon-more-vertical"></i>


      <small *ngIf="this.alerts > 0" class="trans feather icon-info"> {{this.alerts}}</small>

    </a>
  </div>
  <div class="collapse navbar-collapse" [style.display]="this.collapseStyle">
    <!-- <app-nav-left class="mr-auto"></app-nav-left> -->
    <app-nav-right class="ml-auto"></app-nav-right>
  </div>
</ng-template>